/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentmodified'
})
export class MomentmodifiedPipe implements PipeTransform {

  transform(date: Date, type: string): any {

    const now = moment();

    if (type === 'isNew') {
      const diffDate = moment(date);

      if(now.diff(diffDate, 'days') < 1) {
        return moment(date).format('HH:mm:ss');
      } else {
        return moment(date).format('DD.MM.YYYY')
      }
    }


    return date;
  }

}
