/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Widget, WidgetConfiguration } from './widget';
import { CommonUtil } from '../../../common/util/common.util';

export class TextWidget extends Widget {

  public configuration:TextWidgetConfiguration;

  constructor(contents?: string) {
    super();
    this.id = CommonUtil.getUUID();
    this.name = 'Text Widget';
    this.type = 'text';
    this.configuration = new TextWidgetConfiguration(contents);
  }

} // Class - TextWidget

export class TextWidgetConfiguration extends WidgetConfiguration {

  public contents: string;

  constructor(contents?: string) {
    super();
    this.type = 'text';
    if (contents) {
      this.contents = contents;
    }
  }
} // Class - TextWidgetConfiguration
