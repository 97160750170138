import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
  name: 'short',
  pure: false
})
@Injectable()
export class ShortNamePipe implements PipeTransform {
  /*
   * @param items object from array
   * @param term term's search
   * @param objectFieldName (optional)
   */
  transform(items: any, term: number): any {
    if ( !term || !items ) return items;
    return ShortNamePipe.filter(items, term);
  }
  /*
   * @param items List of items to filter
   * @param term  a string term to compare with every property of the list
   * @param objectFieldName
   */
  static filter(items: any, term: number): string {
    const toCompare = Number(term);
    if ( term === null || term === undefined ) { // Object Field not defined so loop over object
      return items.splice(0, 20).concat('...');
    }
    return items.slice(0, term).concat('...');
  }
}
