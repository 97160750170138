/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '../../../common/component/abstract.component';
import { CommonUtil } from '../../../common/util/common.util';

@Component({
  selector: 'workbook-confirm-delete',
  styleUrls: ['workbook-confirm-delete.scss'],
  templateUrl: './workbook-confirm-delete.html'
})
export class WorkbookConfirmDelete extends AbstractComponent implements OnInit, OnDestroy {

  constructor(protected elementRef: ElementRef,
              protected injector: Injector) {
    super(elementRef, injector);
  }

  @Output() confirmDelete = new EventEmitter<boolean>();

  public confirmDeleteDel() {
    this.confirmDelete.emit(true);
  }

  public confirmDeleteCancel() {
    this.confirmDelete.emit(false);
  }

}
