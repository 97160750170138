/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export enum SYSTEM_PERMISSION {
  VIEW_WORKSPACE = <any>'PERM_SYSTEM_VIEW_WORKSPACE',
  MANAGE_WORKSPACE = <any>'PERM_SYSTEM_MANAGE_WORKSPACE',
  MANAGE_PRIVATE_WORKSPACE = <any>'PERM_SYSTEM_MANAGE_PRIVATE_WORKSPACE',
  MANAGE_SHARED_WORKSPACE = <any>'PERM_SYSTEM_MANAGE_SHARED_WORKSPACE',
  MANAGE_DATASOURCE = <any>'PERM_SYSTEM_MANAGE_DATASOURCE',
  MANAGE_METADATA = <any>'PERM_SYSTEM_MANAGE_METADATA',
  MANAGE_USER = <any>'PERM_SYSTEM_MANAGE_USER',
  MANAGE_SYSTEM = <any>'PERM_SYSTEM_MANAGE_SYSTEM'
}

export enum WORKSPACE_PERMISSION {
  PERM_WORKSPACE_MANAGE_WORKSPACE = <any>'PERM_WORKSPACE_MANAGE_WORKSPACE',
  PERM_WORKSPACE_MANAGE_FOLDER = <any>'PERM_WORKSPACE_MANAGE_FOLDER',
  PERM_WORKSPACE_VIEW_WORKBOOK = <any>'PERM_WORKSPACE_VIEW_WORKBOOK',
  PERM_WORKSPACE_EDIT_WORKBOOK = <any>'PERM_WORKSPACE_EDIT_WORKBOOK',
  PERM_WORKSPACE_MANAGE_WORKBOOK = <any>'PERM_WORKSPACE_MANAGE_WORKBOOK',
  PERM_WORKSPACE_VIEW_NOTEBOOK = <any>'PERM_WORKSPACE_VIEW_NOTEBOOK',
  PERM_WORKSPACE_EDIT_NOTEBOOK = <any>'PERM_WORKSPACE_EDIT_NOTEBOOK',
  PERM_WORKSPACE_MANAGE_NOTEBOOK = <any>'PERM_WORKSPACE_MANAGE_NOTEBOOK',
  PERM_WORKSPACE_VIEW_WORKBENCH = <any>'PERM_WORKSPACE_VIEW_WORKBENCH',
  PERM_WORKSPACE_EDIT_WORKBENCH = <any>'PERM_WORKSPACE_EDIT_WORKBENCH',
  PERM_WORKSPACE_MANAGE_WORKBENCH = <any>'PERM_WORKSPACE_MANAGE_WORKBENCH'
}
