import { Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractComponent } from 'app/common/component/abstract.component';
import { EventBroadcaster } from 'app/common/event/event.broadcaster';
import { CookieService } from 'ng2-cookies';

@Component({
  selector: 'app-seahorse-frame',
  templateUrl: './pipeline-frame.component.html',
  styleUrls: ['./pipeline-frame.component.sass']
})
export class PipelineFrameComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('iframe') frame: ElementRef;

  public locality: any = window.location.hostname;
  public yt: any;

  constructor(private broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
    private $render: Renderer2,
    private cookie: CookieService) {
    super(elementRef, injector);
  }

  content = `<button
    (click)="onClick()">
    This is a Clickable span
  </button>`;



  public ngOnInit() {
    // Init
    super.ngOnInit();
    this.cookie.set('pipeline', '%7B%22id%22%3A%2200000000-0000-0000-0000-000000000001%22%2C%22name%22%3A%22user%22%7D')
    this.cookie.set('_gat', '1')
    this.cookie.get('pipeline')
    if (this.locality == 'localhost') {
      this.yt = '<iframe class="w-100 ddp-ui-space-contents-iframe-height" src="http://client.localhost" target="_top" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
    } else {
      this.yt = '<iframe class="w-100 ddp-ui-space-contents-iframe-height" src="/sh/index.html" target="_top" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
    }
  }
  public ngOnDestroy() {
    super.ngOnDestroy();
  }


}
