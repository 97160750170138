/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as _ from 'lodash';

import {
  Component, ElementRef, Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {AbstractComponent} from "../../common/component/abstract.component";

@Component({
  selector: 'app-page-loading',
  templateUrl: 'page-loading.component.html',
  styleUrls: ['./page-loading.component.css']
})
export class PageLoadingComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(protected elementRef: ElementRef,
              protected injector: Injector,) {
    super(elementRef, injector);
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
