/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Created by Dolkkok on 2017. 7. 18..
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {BaseChart} from '../base-chart';
import {BaseOption} from '../option/base-option';
import {
  AxisType,
  BarMarkType, CHART_STRING_DELIMITER,
  ChartType,
  DataZoomRangeType, EventType,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartDataLabelDisplayType
} from '../option/define/common';
import {OptionGenerator} from '../option/util/option-generator';
import {Pivot} from '../../../../domain/workbook/configurations/pivot';
import * as _ from 'lodash';
import {UIChartAxis, UIChartAxisLabelValue, UIOption} from '../option/ui-option';
import {DataZoomType} from '../option/define/datazoom';
import {UIChartAxisGrid} from '../option/ui-option/ui-axis';
import {Axis} from '../option/define/axis';
import {AxisOptionConverter} from '../option/converter/axis-option-converter';
import {LabelOptionConverter} from '../option/converter/label-option-converter';
import {TooltipOptionConverter} from '../option/converter/tooltip-option-converter';
import * as Highcharts from 'highcharts';
import {DatasourceService} from "../../../../datasource/service/datasource.service";
import {Observable} from "rxjs/Rx";
import {directiveDef} from "@angular/core/src/view";
import {any} from "codelyzer/util/function";


@Component({
  selector: 'dpie-chart',
  templateUrl: '3d-pie-chart.component.html'
})
export class DPieChartComponent extends BaseChart implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() uiOption;
  @Input() data;
  @Input() aggregation;
  @Input() dataSource;
  @Input() events: Observable<any>;

  public objChart: any;
  public options: any = this.uiOption;
  public dataSourceLenght: number;
  public transitData: any = [];
  public resultData: any = [];
  public modifyData: any = [];
  public counter: any = 0;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector, private _datasourceService: DatasourceService) {
    super(elementRef, injector);
  }

  public isValid(pivot: Pivot): boolean {
    return true;
  }

  public ngOnInit() {
    super.ngOnInit();
    this.uiOption.title.text = '3d Pie'
    this.events.subscribe(() => {
      if(this.isAggregate() == false) {

      } else {
        console.group('Aggregation');
        console.groupEnd();
        this.modifyData = [];
        let resultData = this.getDataSource();
        console.group('result Data -- Aggregation');
        console.log(this.resultData);
        console.groupEnd();
        resultData.then(res => {
          resultData = res;
          return res;
        }).then(res => {
          let resultDataKeys = Reflect.ownKeys(resultData[0]);
          res.forEach((itemx, i) => {
            let resultString = [];
            this.counter = 0;
            resultDataKeys.forEach((itemy, index) => {
              if(itemx[itemy] == null || itemx[itemy] == 'null') {
                itemx[itemy] = 'Empty value';
              }
              resultString.push(itemx[itemy]);
            })
            this.modifyData.push(resultString);
          })
          if(this.modifyData.length < 2) {
            this.modifyData = [];
            this.modifyData.push(['Empty data', 100]);
          }
          console.group('modify Data -- Aggregation');
          console.log(this.modifyData);
          console.groupEnd();
          this.uiOption.series[0].data = this.modifyData;
        }).then(res => {
          Highcharts.chart('container', this.uiOption).redraw(true);
        });
      }
    });

    let resultData = this.getDataSource();
    resultData.then(res => {
      resultData = res;
      return res;
    }).then(res => {
      let resultDataKeys = Reflect.ownKeys(resultData[0]);
      res.forEach((itemx, i) => {
        let resultString = [];
        this.counter = 0;
        resultDataKeys.forEach((itemy, index) => {
          if(itemx[itemy] == null || itemx[itemy] == 'null') {
            itemx[itemy] = 'Empty value';
          }
          resultString.push(itemx[itemy]);
        })
        this.modifyData.push(resultString);
      })
      if(this.modifyData[0].length != 2) {
        this.modifyData = [];
        this.modifyData.push(['Empty data', 100]);
      }
      console.group('modify Data -- Init');
      console.log(this.modifyData);
      console.groupEnd();
      this.uiOption.series[0].data = this.modifyData;
      Highcharts.chart('container', this.uiOption).redraw(true);
    });

  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.uiOption != this.uiOption) {
      Highcharts.chart('container', this.uiOption);
    }
  }

  public ngOnChanges() {

  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    Highcharts.chart('container', this.uiOption).destroy()
  }

  getDataSource() {
    let projection = [];
    this.aggregation.forEach(item => {
      if (item.type === 'dimension') {
        projection.push({
          "type": "dimension",
          "name": item.name
        })
      }
      if (item.type == 'measure') {
        projection.push({
          "type": "measure",
          "name": item.name,
          "aggregationType": item.aggregationType,
          "alias": `percentage`
        })
      }
    })


    let projectionByTypeName = projection.slice(0);
    projectionByTypeName = projectionByTypeName.sort((a, b) => {
      let x = a.type.toLowerCase();
      let y = b.type.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });


    let obj = {
      "dataSource": {
        "type": "default",
        "name": this.dataSource.engineName
      },
      "filters": [],
      "projections": projectionByTypeName,
      "limits": {
        "limit": 1000000,
        "sort": [
          {
            "field": "importance",
            "direction": "DESC"
          }
        ]
      }
    }


    return this._datasourceService.getDatasourceQuery(obj);
  }

  public isAggregate() {
    let measure = 0;
    let dimension = 0;
    let result;
    this.aggregation.map(item => {
      if(item.type == 'measure') {
        measure = measure + 1;
      }
      if(item.type == 'dimension') {
        dimension = dimension + 1;
      }
    })
    if(measure === 1 && dimension === 1) {
      result = true;
      return result;
    } else {
      result = false;
      return result;
    }
  }


}
