/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js/dist/zone';  // from polyfills.ts
// import { hmrBootstrap } from './hmr';
import { hmrModule } from '@angularclass/hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr === true) {
  if (module['hot']) {
    // hmrBootstrap(module, bootstrap);
    bootstrap().then((ngModuleRef) => {
      return hmrModule(ngModuleRef, module);
    }).catch(err => console.log(err));
  } else {
    // console.error('HMR is not enabled for webpack-dev-server!');
    // console.log('Are you using the --hmr flag for ng serve?');
    bootstrap().catch((err) => console.log(err));
  }
} else {
  bootstrap().catch(err => console.log(err));
}
