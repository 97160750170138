import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Injector,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, QueryList, SimpleChanges,
  ViewChild, ViewChildren
} from '@angular/core';
import {AbstractComponent} from "../../../common/component/abstract.component";
import * as _ from "lodash";
import {Dashboard, PresentationDashboard} from "../../../domain/dashboard/dashboard";
import {PopupService} from "../../../common/service/popup.service";
import {DashboardService} from "../../../dashboard/service/dashboard.service";
import {Alert} from "../../../common/util/alert.util";
import {StringUtil} from "../../../common/util/string.util";
import {CommonUtil} from "../../../common/util/common.util";
import {WorkspaceService} from "../../../workspace/service/workspace.service";
import {WorkbookService} from "../../service/workbook.service";
import {animate, animateChild, group, query, state, style, transition, trigger} from "@angular/animations";
import {Workbook, WorkbookDetailProjections} from "../../../domain/workbook/workbook";

import {
  CdkDrag,
  CdkDragDrop,
  CdkDragEnter,
  CdkDragExit,
  CdkDragStart,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem
} from "@angular/cdk/drag-drop";
import {CookieConstant} from '../../../common/constant/cookie.constant';
import {Page, PageResult} from '../../../domain/common/page';
import {Subscription} from 'rxjs/Subscription';
import {UserProfile} from '../../../domain/user/user-profile';
import {Comments} from '../../../domain/comment/comment';
import {ActivatedRoute} from '@angular/router';
import set = Reflect.set;
import {DashboardUtil} from "../../../dashboard/util/dashboard.util";
import {HttpClient, HttpRequest} from "@angular/common/http";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-workbook-navigation',
  templateUrl: './workbook-navigation.component.html',
  styleUrls: ['./workbook-navigation.component.sass'],
  animations: [
    trigger('slideInOut', [
      state('in', style({height: '*', opacity: 1})),
      transition('* <=> *', [
        query(
          ':leave',
          [
            style({height: '*', opacity: 1}),
            group([
              animate('100ms 100ms ease-in-out', style({height: 0})),
              animate('100ms ease-in-out', style({'opacity': '0'})),
            ])
          ],
          {optional: true}
        ),
        query(':enter', [
            style({height: '0', opacity: 0}),

            group([
              animate('100ms ease-in-out', style({height: '*'})),
              animate('100ms 100ms ease-in-out', style({'opacity': '1'}))
            ])

          ],
          {optional: true}
        )
      ])
    ]),

    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('30ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('30ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    ),
    trigger(
      'enterAnimationUp', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('30ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('30ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]
    ),
    trigger(
      'enterAnimationDown', [
        transition(':enter', [
          style({transform: 'translateY(-100%)', opacity: 0}),
          animate('30ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('30ms', style({transform: 'translateY(-100%)', opacity: 0}))
        ])
      ]
    ),
    trigger(
      'enterAnimationToggle', [
        transition(':enter', [
          style({opacity: 0}),
          animate('0ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('0ms', style({opacity: 0}))
        ])
      ]
    )

  ],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class WorkbookNavigationComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, AfterViewChecked {
  @Input() workbook;
  @Input() dashboards;
  @Input() _boardComp;
  @Input() selectedDashboard;
  @Input() isChangeAuthUser;
  @Input() onlyShowingFlag;
  @Input() workbookId;
  @Input() workspace;
  @Input() publicType;
  @Input() itemId;
  @Input() selectedDatasources;
  @Input() datasources;
  @Input() dashboardSrchText;
  @Input() dashboardPage;
  @Input() isShowMoreDashboardList;
  @Input() dashboardId;
  @Input() dashboardUtil;
  @Input() confirmDeleteInput;
  @ViewChild("navigation") navigation;
  @ViewChildren("navigation") navChildren;
  @ViewChild('leftMenu') leftMenu;

  @Output() dashBoard = new EventEmitter<string>();
  @Output() dashboardName = new EventEmitter<string>();
  @Output() gridMenu = new EventEmitter<boolean>();
  @Output() modalSetProfile = new EventEmitter<boolean>();
  @Output() clientIDDataEmitter = new EventEmitter<boolean>();

  public gridMenuState = false;
  public modalSetter = false;

  public confirmModal = false;
  public clientIDData: any = '';

  public confirmModalFunc(event) {
    this.confirmModal = !this.confirmModal;
    this.modalSetProfile.emit(event);
    let obj = {"json": true};
    // this.clientIDDataEmitter.emit("[obj]");
    const headers = new Headers();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    let profileData = {
      "username": "yera",
      "password": "1q2w3e4r"
    }
    // this._http.post('http://176.9.124.111:8199/login', profileData).subscribe(req => {
    //   let clientID = req['access_token'];
    //   localStorage.setItem('bearer_for_ClientID', clientID);
    //
    //   let headers = new Headers();
    //
    //   headers.append('Authorization', `Bearer ${clientID}`);
    //
    //   fetch('http://176.9.124.111:8199/api/clientAccounts?projection=clientAccountFull&clientId.id=1', {
    //     mode: 'cors',
    //     credentials: 'include',
    //     method: 'GET',
    //     headers: headers
    //   })
    //     .then(response => response.json())
    //     .then(json => {
    //       this.clientIDDataEmitter.emit(json);
    //     })
    //     .catch(error => console.log('Authorization failed : ' + error.message));
    // })
  }


  @Output() confirmDelete = new EventEmitter<boolean>();
  public confirmDel = false;


  public confirmDelFunc(dashboard) {
    this.confirmDel = true;
    this.confirmDelete.emit(dashboard);
  }

  @HostListener('window:onload', ['$event'])

  public counter = 0;
  public isShowCreateDashboard: boolean;
  public useUnloadConfirm: boolean = false;

  public updateDashboardStartupCmd: { cmd: string, id?: string, type?: string };
  public mode;
  public isDashboardNameEditMode = false;
  public isDashboardDescEditMode = false;
  public tempLoadBoard;
  public listType = 'LIST';
  public editName = true;
  public intervalResizer: any;

  // Options
  public dropdownMenu: boolean = false;
  public pinMenu: boolean = false;
  public trumbView: boolean = false;
  public pinSearch: boolean = true;
  public arrows: boolean = true;
  public isCloseDashboardList: boolean = false;
  // search Dashbords
  public searchDashbords: string;
  public asyncAnimation: boolean = false;
  public interval: any;
  public dashboardsPins: any = [
    {name: 'ok'}
  ];
  public showActionsBtnPanel = false;

  public username: any;

  protected subscriptions: Subscription[] = [];

  constructor(private popupService: PopupService,
              private dashboardService: DashboardService,
              private workspaceService: WorkspaceService,
              private workbookService: WorkbookService,
              private activateRoute: ActivatedRoute,
              private _formBuilder: FormBuilder,
              protected elementRef: ElementRef,
              protected injector: Injector,
              public _http: HttpClient) {
    super(elementRef, injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this._initViewPage();
    this.getPins();
    this.intervalResize();
    this.intervalGettingStartedPipeline();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngAfterViewChecked(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.intervalCleaner();
  }


  public clearWindowUI() {
    window['ui'] ? window['ui'][0]['widgets'] = [] : '';
  }

  public addPanel(type) {
    switch (type) {
      case 'NEW_ACTION_BUTTONS' :
        this.showActionsBtnPanel = !this.showActionsBtnPanel;
        this.showCharts = false;
        break;
    }
  }

  public setBtnPanel() {
    this.showActionsBtnPanel = !this.showActionsBtnPanel;
  }

  public intervalResize() {
    this.intervalResizer = setInterval(() => {
      this.allWidth();
      this.sizeStable();

    }, 2000);
  }

  setGridMenu() {
    this.gridMenuState = !this.gridMenuState;
    this.gridMenu.emit(this.gridMenuState)
  }

  hideGridMenu() {
    this.loadingShow()
    this.gridMenuState = false;
    this.gridMenu.emit(this.gridMenuState);
    this.loadingHide();
  }

  public intervalCleaner() {
    clearInterval(this.intervalResizer);
  }


  public sizeStable() {
    let li = $('.workbook-nav-ul').children().outerWidth(true);
    let liLen = $('.workbook-nav-ul').children().length;
    let ul = $('.workbook-nav-ul').width();

    ((li * liLen) > ul) ? this.arrows = true : this.arrows = false;
  }

  public allWidth() {
    let all = $('.workbook-nav-ul').width();

    let result = all % 120;
    let ok = 0;
    let width = $('.workbook-nav-ul').children().outerWidth(true);
    let len = $('.workbook-nav-ul').children().length;
    let res;
    if (result != 0 || (width * len) > len) {
      res = width * len;
      this.getWidthPinsMenu();
      this.checkAddingPins();
    }
  }

  private _initViewPage() {
    this.username = CommonUtil.getLoginUserId();

    const cookieIsCloseDashboardList = this.cookieService.get(CookieConstant.KEY.WORKBOOK_CLOSE_DASHBOARD_LIST);
    this.toggleFoldDashboardList('true' === cookieIsCloseDashboardList);

    this.changeMode('NO_DATA');
  } // function - _initViewPage

  public toggleFoldDashboardList(isClose?: boolean) {
    if ('undefined' === typeof isClose) {
      this.isCloseDashboardList = !this.isCloseDashboardList;
    } else {
      this.isCloseDashboardList = isClose;
    }
    (this._boardComp) && (this._boardComp.toggleFoldWorkbookDashboardList());

    // 쿠키 저장
    this.cookieService.set(CookieConstant.KEY.WORKBOOK_CLOSE_DASHBOARD_LIST, String(this.isCloseDashboardList), 0, '/');
  } // function - toggleFoldDashboardList

  public setDashboard(dashboard: any) {
    this.dashBoard.emit(dashboard);
  }

  public setDashboardName(dashboard: any) {
    this.dashboardName.emit(dashboard);
  }

  public scrollRight(): void {
    this.navigation.nativeElement.scrollTo({
      left: (this.navigation.nativeElement.scrollLeft + 360),
      behavior: 'smooth'
    });
  }

  public scrollLeft(): void {
    this.navigation.nativeElement.scrollTo({
      left: (this.navigation.nativeElement.scrollLeft - 360),
      behavior: 'smooth'
    });
  }

  public createDashboard() {
    this.isShowCreateDashboard = true;
    this.useUnloadConfirm = true;
    this.safelyDetectChanges();
  } // function - createDashboard

  public closeCreateDashboard() {
    this.isShowCreateDashboard = false;
    this.useUnloadConfirm = false;
    this.safelyDetectChanges();
  } // function - closeCreateDashboard

  public changeMode(mode: string, startupCmd?: { cmd: string, id?: string, type?: string }) {
    this.updateDashboardStartupCmd = startupCmd ? startupCmd : {cmd: 'NONE'};
    this.mode = mode;
    this.safelyDetectChanges();
  } // function - changeMode

  public dashboardsMod = [];

  public gotoPresentationView(getDashboard, dashboardsPinsMod) {
    let dashboardMod = this.dashboardsPins;
    this.dashboardsPins.forEach((item, index) => {
      this.dashboards.forEach((it, i) => {
        if (item.id == it.id && item.name == it.name) {
          this.dashboardsMod.splice(index, 0, it);
        }
      })
    });
    this.dashboardsMod.forEach((item, index) => {
      if (item.id == this.selectedDashboard.id) {
        this.popupService.ptSelectedIdx = index;
      }
    })
    // 데이터 설정
    this.popupService.ptDashboards = _.cloneDeep(this.dashboardsPins);
    this.popupService.ptWorkbookId = _.cloneDeep(this.workbookId);
    let boardInfo: PresentationDashboard = <PresentationDashboard>_.cloneDeep(getDashboard);
    this.popupService.ptStartDashboard = boardInfo;
    // 페이지 호출
    this.router.navigate([`/dashboard/presentation/${this.workbook.id}/${getDashboard.id}`]).then();
  } // function - gotoPresentationView

  public updateCompleteDashboard(dashboard) {
    // 업데이트한 대시보드 선택처리
    this._setSelectedDashboard(dashboard);
    // 좌측 대시보드 리스트 썸네일 갱신
    this.dashboards.forEach((board) => {
      if (board.id === dashboard.id) {
        board.imageUrl = dashboard.imageUrl;
      }
    });

    // mode
    this.changeMode('VIEW');
  } // function - updateCompleteDashboard

  private _setSelectedDashboard(dashboard) {
    this.selectedDashboard = dashboard;
    this.dashboardService.setCurrentDashboard(dashboard);
  }

  public onChangeModeName(event: MouseEvent) {
    if (this.isChangeAuthUser) {
      event.stopPropagation();
      this.isDashboardNameEditMode = true;
      this.isDashboardDescEditMode = false;
      this.changeDetect.detectChanges();
    }
  } // function - onChangeModeName

  public renameDashboard(inputName: string) {
    this.isDashboardNameEditMode = false;
    inputName = inputName ? inputName.trim() : '';
    if (inputName && 0 < inputName.length) {
      this.selectedDashboard.name = inputName;

      new Promise(resolve => {
        resolve(this.updateDashboard(this.selectedDashboard))
      }).then(res => {
        console.log(res)
        setTimeout(() => {
          this.getPins();
        }, 200);
      })

    } else {
      Alert.warning(this.translateService.instant('msg.alert.edit.name.empty'));
    }
  } // function - renameDashboard

  public updateDashboard(dashboard: Dashboard) {

    this.dashboards.forEach((db) => {
      if (db.id === dashboard.id) {
        // 대시보드 목록과 이름 둘중에 하나라도 변했을 경우
        if (this._dashboardValidation(dashboard) && (db.name !== dashboard.name || db.description !== dashboard.description)) {

          const param = {
            name: dashboard.name,
            description: dashboard.description
          };

          this.loadingShow();
          this.dashboardService.updateDashboard(dashboard.id, param).then(() => {
            // 성공시 목록 데이터 갱신
            db.name = dashboard.name;
            db.description = dashboard.description;
            Alert.success(this.translateService.instant('msg.comm.alert.save.success'));
            this.changeDetect.detectChanges();
            this.loadingHide();
          }).catch(() => {
            Alert.error(this.translateService.instant('msg.comm.alert.save.fail'));
            this.loadingHide();
          });

        }
        return true;
      }
      return false;
    });
    return true;
  } // function - updateDashboard

  private _dashboardValidation(dashboard: Dashboard) {
    if (StringUtil.isEmpty(dashboard.name)) {
      Alert.warning(this.translateService.instant('msg.alert.edit.name.empty'));
      return false;
    }

    if (CommonUtil.getByte(dashboard.name) > 150) {
      Alert.warning(this.translateService.instant('msg.alert.edit.name.len'));
      return false;
    }

    if (dashboard.description != null
      && CommonUtil.getByte(dashboard.description) > 450) {

      Alert.warning(this.translateService.instant('msg.alert.edit.description.len'));
      return false;
    }

    return true;
  } // function - _dashboardValidation

  public changeDesc(inputDesc: string) {
    this.isDashboardDescEditMode = false;
    this.selectedDashboard.description = inputDesc;
    this.updateDashboard(this.selectedDashboard);
  } // function - changeDesc

  public loadAndSelectDashboard(dashboard: Dashboard) {

    this.tempLoadBoard = dashboard;
    if (this.isInvalidDatasource(dashboard)) {
      if (this._boardComp) {
        this._setSelectedDashboard(undefined);
        this._boardComp.showError(this.translateService.instant('msg.space.ui.dashboard.unauthorized'));
        this._boardComp.hideBoardLoading();
      }
    } else {
      if (!this.selectedDashboard || this.selectedDashboard.id !== dashboard.id) {
        if (this._boardComp) {
          this._boardComp.showBoardLoading();
          this._boardComp.hideError();
        }
        this.dashboardService.getDashboard(dashboard.id).then((board: Dashboard) => {
          // save data for selected dashboard
          board.workBook = this.workbook;
          this._setSelectedDashboard(board);
          this.tempLoadBoard = undefined;

          this.scrollToDashboard(board.id); // scroll to item

          (this._boardComp) && (this._boardComp.hideBoardLoading());
          this.safelyDetectChanges();
        }).catch(() => {
          if (this._boardComp) {
            this._boardComp.showError();
            this._boardComp.hideBoardLoading();
          }
          this.safelyDetectChanges();
        });
      } else {
        this._boardComp.hideBoardLoading();
        this.safelyDetectChanges();
      }
    }
  } // function - loadAndSelectDashboard
  public isInvalidDatasource(dashboard: Dashboard): boolean {
    return false;
  }

  public scrollToDashboard(dashboardId: string) {
    const selectedIdx: number = this.dashboards.findIndex(item => item.id === dashboardId);
    if ('LIST' === this.listType) {
      $('.ddp-ui-board-listview').animate({scrollTop: selectedIdx * 52}, 800, 'swing');
    } else {
      $('.ddp-ui-board-thumbview').animate({scrollTop: selectedIdx * 185}, 800, 'swing');
    }
  } // function - scrollToDashboard

  public get filteredDashboard(): Dashboard[] {
    // 대시보드 리스트 권한별 show
    if (this.onlyShowingFlag) {
      return this.dashboards.filter((ds) => {
        return (ds.hiding === true || ds.hiding === undefined);
      });
    }

    return this.dashboards;
  }

  public detailDashboard(dashboard: Dashboard) {
    this.loadAndSelectDashboard(dashboard);
  }

  public changeFavorite(dashboard: Dashboard, event) {
    event.stopImmediatePropagation();

    const params = {
      hiding: !dashboard.hiding
    };

    this.dashboardService.updateDashboard(dashboard.id, params).then((ds) => {
      dashboard.hiding = ds.hiding;
    });
  } // function - changeFavorite
  public changeOrder() {

    // 로딩 show
    this.loadingShow();

    // 요청할 대시보드 리스트
    const params = [];
    this.dashboards.forEach((item, idx) => {
      params.push({
        op: 'REPLACE',
        hiding: item.hiding,
        id: item.id,
        seq: idx
      });
    });

    // 순서 변경
    this.workbookService.setDashboardSort(this.workbookId, params)
      .then(() => {
        // 성공 알림
        Alert.success(this.translateService.instant('msg.board.alert.dashboard.sort.success'));
        // 로딩 hide
        this.loadingHide();
      })
      .catch(() => {
        // 성공 알림
        Alert.error(this.translateService.instant('msg.board.alert.dashboard.sort.fail'));
        // 로딩 hide
        this.loadingHide();
      });

  } // function - changeOrder

  public addWidget(type: string) {
    switch (type) {
      case 'NEW_CHART' :
        this.changeMode('UPDATE', {cmd: 'NEW', type: 'CHART'});
        break;
      case 'NEW_TEXT' :
        this.changeMode('UPDATE', {cmd: 'NEW', type: 'TEXT'});
        break;
      case 'NEW_FILTER' :
        this.changeMode('UPDATE', {cmd: 'NEW', type: 'FILTER'});
        break;
      case 'NEW_ACTION_BUTTONS' :
        this.changeMode('UPDATE', {cmd: 'NEW', type: 'NEW_ACTION_BUTTONS'});
        break;
    }
  } // function - addWidget

  async animationTimer() {
    if (this.asyncAnimation) {
      this.asyncAnimation = false;
    } else {
      setTimeout(() => {
        this.asyncAnimation = true;
      }, 1000);
    }
  }

  getWidthPinsMenu() {
    $('.workbook-nav-ul').css('width', '0');
    $('.workbook-nav-ul').css("width", () => {

      let all = $('.workbook-nav-ul-wrapper').width();
      //
      let result = all % 120;
      result = all - result;
      return result.toString() + 'px';
    });
    // $('.workbook-nav-ul-wrapper').css('width', () => {
    //   let all = $('.workbook-nav-ul').width();
    //   all = all + 80;
    //   return all.toString() + 'px';
    // });
    $('.workbook-nav-ul').animate({
      display: "flex",
      opacity: "1"
    }, 200);
  }

  deleteDashboard(getDashboard: Dashboard) {
    const dashboard: Dashboard = getDashboard;
    // 대쉬보드 삭제
    this.loadingShow();
    this.dashboardService.deleteDashboard(this.selectedDashboard.id).then(() => {
      Alert.success(this.translateService.instant('msg.board.alert.dashboard.del.success'));

      // 현재 보고있는 페이지 인 경우
      if (this.selectedDashboard.id === dashboard.id) {
        this._setSelectedDashboard(null);
      }

      this._getWorkbook().then(() => {
        this.selectedDatasources = this.datasources.map(ds => ds.id);
        this.loadingHide();
        this.loadDashboardList(0);
      });
    });
  }

  deleteDashboardId(id) {
    // 대쉬보드 삭제
    this.loadingShow();
    this.dashboardService.deleteDashboard(id).then(() => {
      Alert.success(this.translateService.instant('msg.board.alert.dashboard.del.success'));

      // 현재 보고있는 페이지 인 경우
      if (this.selectedDashboard.id === id) {
        this._setSelectedDashboard(null);
      }

      this._getWorkbook().then(() => {
        this.selectedDatasources = this.datasources.map(ds => ds.id);
        this.getPins();
        this.loadingHide();
        this.loadDashboardList(0);
      });
    });
  }

  private _getWorkbook(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.workbookService.getWorkbook(this.workbookId).then((result) => {
        // 워크북이 들고있는 전체 데이터 소스 목록
        this.datasources = result.dataSource.map((ds) => {
          return {id: ds.id, name: ds.name, valid: ds.valid};
        });

        // 최초 아무것도 선택이 안 되어있는 경우는 전체 선택
        if (this.selectedDatasources === undefined) {
          this.selectedDatasources = this.datasources.map(ds => ds.id);
        }

        this.workbook = result;

        resolve(result);
      }).catch((err) => reject(err));
    });
  } // function - _getWorkbook

  public gotoWorkspace() {
    const cookieWs = this.cookieService.get(CookieConstant.KEY.CURRENT_WORKSPACE);
    let cookieWorkspace = (cookieWs) ? JSON.parse(cookieWs) : null;
    if (null !== cookieWorkspace && null !== cookieWorkspace['workspaceId']) {
      this.router.navigate(['/workspace', cookieWorkspace['workspaceId']]).then();
    } else {
      this.router.navigate(['/workspace']).then();
    }
  } // function - gotoWorkspace

  public loadDashboardList(page: number = 0, targetBoardId?: string) {
    this.loadingShow();

    if (!this.workspace) return;

    const params = {
      includeHidden: this.isChangeAuthUser,
      datasources: this.selectedDatasources.join(',')
    };
    if ('' !== this.dashboardSrchText.trim()) {
      params['nameContains'] = this.dashboardSrchText.trim();
    }

    const pageInfo: Page = new Page();
    pageInfo.page = page;

    if (0 === page) {
      this.dashboardPage = new PageResult();
      this.dashboards = [];
      this.isShowMoreDashboardList = false;
    }

    this.workbookService.getDashboards(
      this.workbook.id, {key: 'seq', type: 'asc'}, pageInfo, 'forListView', params
    ).then((result) => {
      let tempList = [];
      if (result.hasOwnProperty('_embedded') && result['_embedded'].hasOwnProperty('dashboards')) {
        tempList = result['_embedded']['dashboards'];
        tempList.forEach((ds) => {
          if (ds.hiding === undefined) ds.hiding = false;
        });
        // API 상에서 권한자가 아닐 경우에 hiding 된 목록은 내려오지 않는 것이 맞으나,
        // API 에서 처리되지 않을 경우를 위해 필터링을 추가함
        if (!this.isChangeAuthUser) {
          tempList = tempList.filter(item => !item.hiding);
        }
      }

      if (result.page) {
        const objPage: PageResult = result.page;
        this.dashboardPage = objPage;
        if (0 === objPage.number) {
          this.dashboards = tempList;
        } else {
          this.dashboards = this.dashboards.concat(tempList);
        }
        this.isShowMoreDashboardList = (0 < objPage.totalPages && objPage.number !== objPage.totalPages - 1);
      }

      // 업데이트에서 대시보드 생성한 후 조회 일 경우 모드 갱신 안함
      if (this.mode !== 'UPDATE') {
        if (this.dashboards.length === 0) {
          this.changeMode('NO_DATA');
        } else {
          this.changeMode('VIEW');
        }
      }

      this.loadingHide();

      // Select Dashboard
      if (0 < this.dashboards.length) {
        if (targetBoardId) {
          const selectedBoard: Dashboard = this.dashboards.find(item => item.id === targetBoardId);
          if (selectedBoard) {
            this.loadAndSelectDashboard(selectedBoard);
          } else {
            if (this.dashboardPage.totalPages > this.dashboardPage.number + 1) {
              this.loadDashboardList(this.dashboardPage.number + 1, targetBoardId);
            }
          }
        } else {
          // if dashboard id is not undefined
          // load chosen dashboard
          if (this.dashboardId !== undefined) {
            const index = this.dashboards.findIndex((dashboard) => {
              return dashboard.id === this.dashboardId;
            });
            this.loadAndSelectDashboard(this.dashboards[index]);
            // else load first dashboard in dashboard list
          } else {
            this.loadAndSelectDashboard(this.dashboards[0]);
          }
        }
      } else {
        this._setSelectedDashboard(null);
      }

      // detect changes
      this.safelyDetectChanges();

    }).catch(() => {
      Alert.error(this.translateService.instant('msg.comm.alert.del.fail'));
      this.loadingHide();
    });
  } // function - loadDashboardList

  public copyDashboard(did, dname) {

    // // 로딩 show
    this.loadingShow();
    this.dashboardService.copyDashboard(did).then((copyBoard: Dashboard) => {
      Alert.success('\'' + dname + '\' ' + this.translateService.instant('msg.board.alert.dashboard.copy.success'));
      this.loadingHide();
      this.loadDashboardList(0, copyBoard.id);
    }).catch(() => this.loadingHide());
  } // function - copyDashboard

  public removeItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);

  }

  drop(event: CdkDragDrop<string[]>) {
    let drag = event.previousContainer.element.nativeElement.children[event.previousIndex].id;
    let drop = event.previousContainer.element.nativeElement.children[event.currentIndex].id;
    let params;
    this.workbookService.changePlacePinsWorkbookID(this.workbookId, drag, drop, params).then(() => {
      this.getPins();
    });
    moveItemInArray(this.dashboardsPins, event.previousIndex, event.currentIndex);
  }

  addInPins(dashboard: any) {
    let params = {};
    this.workbookService.addPinsWorkbook(this.workbookId, dashboard.id, params).then(res => {
      this.getPins();
    });
  }

  allPins(dashboards: any) {
    this.loadingShow();
    let data = [];
    this.dashboards.forEach(item => {
      data.push(item.id);
    })
    this.workbookService.addAllPins(this.workbookId, data).then(() => {
      this.getPins().then(() => {
        this.checkAddingPins();
        this.allWidth();
      });
    });
    this.loadingHide();
  }

  deleteAllPins(dashboards) {
    this.loadingShow();
    this.workbookService.deleteAllPins(this.workbookId).then(() => {
    }).then(() => {
      this.getPins().then(() => {
      });
    }).then(() => {
      this.dashboards.forEach((item, index) => {
        this.dashboards[index].pins = 'false';
      });
      this.dashboards = this.dashboards;
    }).then(() => {
      this.allWidth();
      this.checkAddingPins();
    })
    this.loadingHide();
  };

  public getPins() {
    this.workbookId = this.activateRoute.snapshot.params['workbookId'];
    return this.workbookService.getPinsWorkbook(this.workbookId).then(res => {
      this.dashboardsPins = res.dashBoard;
      this.checkAddingPins();
      this.allWidth();
      this.setDashboardWithPins();
      return this.dashboardsPins;
    });
  }

  public setDashboardWithPins() {
    if (this.counter == 0) {
      this._setSelectedDashboard(this.dashboardsPins[0]);
      this.setDashboard(this.dashboardsPins[0]);
      this.counter = this.counter + 1;
    }
  }

  deletePins(dashboardId: any) {
    this.workbookService.deletePinsWorkbookID(this.workbookId, dashboardId).then(async () => {
      return this.getPins();
    }).then(() => {
      this.setFalsePins(dashboardId);
    });
  }

  setFalsePins(dashboardId) {
    this.dashboards.forEach((item, index) => {
      if (dashboardId == item.id) {
        this.dashboards[index].pins = 'false';
      }
      // if(dashboardId != item.id && this.dashboards[index].pins == undefined) {
      //   this.dashboards[index].pins = false;
      // }
    });
  }

  checkAddingPins() {
    for (let i = 0; i < this.dashboards.length; i++) {
      for (let j = 0; j < this.dashboardsPins.length; j++) {
        if (this.dashboards[i].id == this.dashboardsPins[j].id) {
          this.dashboards[i].pins = true;
        } else {
          if (this.dashboards[i].pins == undefined) {
            this.dashboards[i].pins = 'false';
          }
        }
      }
      if (!this.dashboards[i].pins) {
        this.dashboards[i].pins = 'false';
      }
    }
  }

  checkId(id) {
    let result: any = [];
    let res = this.dashboards.forEach(item => {
      if (item.id == id) {
        result = item;
      }
    });
    if (result.id == id) {
      if (result.pins == true) {
        return true;
      } else {
        return 'false';
      }
    }
  }


  public showCharts: boolean = false;

  showChartsMenu() {
    this.showCharts = !this.showCharts;
  }

  public setViews() {
    this.trumbView = !this.trumbView;
  }

  public onResize(event) {
    this.allWidth();
    this.sizeStable();
  }

  public runPipeline() {
    window['interfaceStart'].runner();
  }

  public stopedPipeline() {
    window['interfaceStart'].setStop();
  }

  public getStatus() {
    window['interfaceStart'].getter();
  }

  public syncPipeline() {
    window['interfaceStart'].synchronize();
  }

  public $window = window;
  public workbookID = '';
  @Input('workbookIDGetter')
  set workbookIDGetter(val) {
    this.$window['interfaceStart']['ID'] = '';
    this.$window['interfaceStart']['ID'] = val;
  }
  public onSubmit() {
    if(this.gettingStopedBtn == false) {
      this.runPipeline();
    } else {
      this.stopedPipeline();
    }
  }
  public gettingStopedBtn: boolean = false;
  public intervalGettingStartedPipeline() {
    console.group('GettingStartedPipelineInterval')
    let interval = setInterval(function() {
      const xhrGet = new XMLHttpRequest()
      xhrGet.open('GET', `https://pipeline1.cognive.com/v1/sessions`)
      xhrGet.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
      xhrGet.onload = function(e) {
        let counter = 0;
        JSON.parse(xhrGet.response).sessions.map(item => {
          if(item.workflowId == window['interfaceStart']['ID']) {
            this.gettingStopedBtn = true;
            counter = counter + 1;
          } else {
            this.gettingStopedBtn = false;
          }
          if(counter > 0) {
            this.gettingStopedBtn = true;
          } else {
            counter = 0;
          }
        });
      }.bind(this);
      xhrGet.send();
    }.bind(this), 1000);
    console.groupEnd();
    // clearInterval(interval);
  }
}


window['interfaceStart'] = [];
window['interfaceStart']['config'] = {
  mquser: 'yNNp7VJS',
  mqpass: '1ElYfGNW',
};


window['interfaceStart']['URL'] = '';
window['interfaceStart']['ID'] = '';
window['interfaceStart']['URL'] = "https://pipeline1.cognive.com";

window['interfaceStart'].getter = function () {
  const xhrGet = new XMLHttpRequest()
  xhrGet.open('GET', `https://pipeline1.cognive.com/v1/sessions`)
  xhrGet.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
  xhrGet.onload = function(e) {
    JSON.parse(xhrGet.response).sessions.map(item => {
      console.log(item.workflowId, window['interfaceStart']['ID']);
    });
    console.log(JSON.parse(xhrGet.response).sessions);
  }
  xhrGet.send();
}

window['interfaceStart'].runner = function () {
  console.log("Runner...");
  let URL = window['interfaceStart']['URL'];
  let ID = window['interfaceStart']['ID'];
  let body = {
    "cluster": {
      "clusterType": 'local',
      "driverMemory": '2G',
      "executorCores": 2,
      "executorMemory": '2G',
      "id": 1,
      "isDefault": true,
      "isEditable": true,
      "name": 'default',
      "numExecutors": 2,
      "params": '',
      "totalExecutorCores": 2,
      "uri": 'local[*]',
      "userIP": ''
    },
    workflowId: ID
  };

  const xhrPost = new XMLHttpRequest()
  xhrPost.open('POST', `${URL}/v1/sessions`)
  xhrPost.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
  xhrPost.send(JSON.stringify(body));
}

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';


window['interfaceStart'].setStop = function () {
  let URL = window['interfaceStart']['URL'];
  let ID = window['interfaceStart']['ID'];
  console.log("Start -- Stopped");
  const xhr = new XMLHttpRequest()
  xhr.open('DELETE', `${URL}/v1/sessions/${ID}`);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
  xhr.send();
  console.log("End -- Stopped");
  // this.client.disconnect();
}
window['interfaceStart'].synchronize = function () {
  let URL = window['interfaceStart']['URL'];
  let ID = window['interfaceStart']['ID'];
  let body = {
    messageType: 'synchronize',
    messageBody: {}
  }
  const xhr = new XMLHttpRequest()
  xhr.open('POST', `${URL}/v1/sessions`);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
  xhr.send(JSON.stringify(body));
}
$('#scrollbar_style').mousemove(function (e) {
  var y = e.pageY - $(this).offset().top;
  $(this).children('.scroll-but').css({
    'top': y + 'px'
  });
})

