/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';
import {ApplicationRef, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";

import * as moment from 'moment';
import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {PopupService} from './common/service/popup.service';
import {EventBroadcaster} from './common/event/event.broadcaster';
import {UnloadConfirmService} from './common/service/unload.confirm.service';
import {CanDeactivateGuard} from './common/gaurd/can.deactivate.guard';
import {SsoGuard} from './common/gaurd/sso.guard';
import {UserService} from './user/service/user.service';
import {CookieService} from 'ng2-cookies';
import {ClipboardModule} from 'ngx-clipboard';
import {LoaderInterceptor} from "./common/interceptor/loader-interceptor";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { StompConfig, StompService } from '@stomp/ng2-stompjs';
import { PipelineModule } from './pipeline/pipeline.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AbstractService} from "./common/service/abstract.service";
import {UpdateDashboardComponent} from "./dashboard/update-dashboard.component";
import {DashboardModule} from "./dashboard/dashboard.module";
import {DashboardUtil} from "./dashboard/util/dashboard.util";
// import { faTrophy } from '@fortawesome/free-solid-svg-icons';

library.add(fas, far);

// 다국어 파일 경로 지정
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// 다국어 모듈 생성
export const appTranslateModule: ModuleWithProviders = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
});


const appRoutes: Routes = [
  {path: 'sso', loadChildren: 'app/sso/sso.module#SsoModule'},
  {path: 'user', loadChildren: 'app/layout/none-layout/none-layout.module#NoneLayoutModule'},
  {path: 'dashboard', loadChildren: 'app/embedded/embedded-view.module#EmbeddedViewModule'},
  {path: 'embedded', loadChildren: 'app/embedded/embedded-view.module#EmbeddedViewModule'},
  {path: 'edit-page', loadChildren: 'app/page/page.module#PageModule'},
  {path: '', loadChildren: 'app/layout/layout/layout.module#LayoutModule', canActivate: [SsoGuard]},
  // 존재하지 않는 URL
  {path: '**', redirectTo: '/user/login', pathMatch: 'full'}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
    appTranslateModule,
    HttpClientModule,
    ClipboardModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    DashboardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: APP_BASE_HREF, useValue: environment.baseHref},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    PopupService,
    SsoGuard,
    UserService,
    CookieService,
    AbstractService,
    CanDeactivateGuard,
    UnloadConfirmService,
    EventBroadcaster,
    UpdateDashboardComponent,
    DashboardUtil
],
  bootstrap: [AppComponent],
  exports: [
    BrowserAnimationsModule
  ],

})
// export class AppModule {
//   constructor() {
//     let lang = window.navigator['userLanguage'] || window.navigator.language || 'en';
//     if (window.navigator['languages']) {
//       lang = window.navigator['languages'][0];
//     }
//     console.info(`browser's language ${lang}`);
//     moment.locale(lang);
//   }
// }
export class AppModule {
  constructor(public appRef: ApplicationRef) {
    // library.addIconPacks(fas, far);
    // library.add(faTrophy);
    // library.add(fas, far);
  }
  hmrOnInit(store) {
    if (!store || !store.state) return;
    // console.log('HMR store', store);
    // console.log('store.state.data:', store.state.data)
    // inject AppStore here and update it
    // this.AppStore.update(store.state)
    if ('restoreInputValues' in store) {
      store.restoreInputValues();
    }
    // change detection
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }
  hmrOnDestroy(store) {
    var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation)
    // inject your AppStore and grab state then set it on store
    // var appState = this.AppStore.get()
    store.state = {data: 'yolo'};
    // store.state = Object.assign({}, appState)
    // save input values
    store.restoreInputValues  = createInputTransfer();
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts()
    delete store.disposeOldHosts;
    // anything you need done the component is removed
  }
}
