import { Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractComponent } from 'app/common/component/abstract.component';
import { EventBroadcaster } from 'app/common/event/event.broadcaster';

@Component({
  selector: 'app-seahorse-frame',
  templateUrl: './reload.component.html'
})
export class ReloadComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(private broadCaster: EventBroadcaster,
              protected elementRef: ElementRef,
              protected injector: Injector,
              ) {
    super(elementRef, injector);
  }

  public wind = window;


  public ngOnInit() {
    super.ngOnInit();
    this.wind.location.pathname = '/pipeline/v1/';
  }
  public ngOnDestroy() {
    super.ngOnDestroy();
  }


}
