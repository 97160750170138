/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { NgModule } from '@angular/core';
import { AnalysisPredictionComponent } from './analysis-prediction.component';
import { RangeSliderModule } from '../slider/range-slider.module';
import { CommonModule } from '../../../../common/common.module';
import { ColorPickerLayerModule } from '../color.picker/color.picker.layer.module';
import { ColorPickerLayerComponent } from '../color.picker/color.picker.layer.component';
import { RangeSliderComponent } from '../slider/range-slider.component';

@NgModule({
  imports: [
    CommonModule,
    RangeSliderModule,
    ColorPickerLayerModule
  ],
  declarations: [
    AnalysisPredictionComponent
  ],
  exports: [
    AnalysisPredictionComponent,
    ColorPickerLayerComponent,
    RangeSliderComponent
  ]
})
export class AnalysisPredictionModule {
}
