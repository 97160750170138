/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Filter } from './filter';

export class BoundFilter extends Filter {

  public min: number;
  public max: number;

  constructor(field: string) {
    super();
    this.type = 'bound';
    this.field = field;
  }


  // for UI
  // candidate 값
  public minValue: number;
  public maxValue: number;
}
