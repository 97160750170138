import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipelineFrameComponent } from './component/seahorse-frame/pipeline-frame.component';
import { IframePipe } from './pipes/ifame';
import { RouterModule, Routes } from '@angular/router';
import { StompService } from '@stomp/ng2-stompjs';
import { socketProvider } from 'app/common/common.module';
import { CommonConstant } from 'app/common/constant/common.constant';
import { CookieConstant } from 'app/common/constant/cookie.constant';
import { DataconnectionService } from 'app/dataconnection/service/dataconnection.service';
import { MetadataService } from 'app/meta-data-management/metadata/service/metadata.service';
import { FormsModule } from '@angular/forms';
import {ReloadComponent} from "./component/reloader/reload.component";

const routes: Routes = [
  {path: 'v1', component: PipelineFrameComponent},
  {path: 'reload', component: ReloadComponent}
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule
  ],
  declarations: [
    PipelineFrameComponent,
    ReloadComponent,
    IframePipe
  ],
  providers: [
    StompService
  ]
})
export class PipelineModule { }
