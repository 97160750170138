/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export class UserSetting {
  theme : Theme;
  language : Language;

  public static getLanguage(lang: string): Language {
    if (lang === 'ko') {
      return Language.KO;
    } else if (lang === 'zh') {
      return Language.ZH;
    } else {
      return Language.EN;
    }
  }
}

export enum Theme {
  WHITE = '',
  DARK = 'theme-dark'
}

export enum Language {
  KO = 'ko',
  EN = 'en',
  ZH = 'zh'
}
