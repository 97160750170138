/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/service/user.service';
import {AbstractService} from "../service/abstract.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {isNullOrUndefined} from "util";
import {CommonConstant} from "../constant/common.constant";
import {CookieService} from "ng2-cookies";
import {defineDocExtension} from "codemirror";

@Injectable()
export class SsoGuard implements CanActivate {
  public http: HttpClient;
  public _abstractService: AbstractService;
  public _cookieService: CookieService;
  constructor(private userService:UserService,
              private injector: Injector) {
    this.http = injector.get(HttpClient);
    this._abstractService = injector.get(AbstractService);
    this._cookieService = injector.get(CookieService);
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if(!this._cookieService.get('LOGIN_TOKEN')) {
      this._cookieService.deleteAll();
    // document.cookie = 'JSESSIONID=dummy;Secure;SameSite=None;'
    // document.cookie = 'LOGIN_USER_ID=adminSecure;SameSite=None;'
    // document.cookie = 'PERMISSION=PERM_SYSTEM_MANAGE_DATASOURCE%3D%3DPERM_SYSTEM_MANAGE_PRIVATE_WORKSPACE%3D%3DPERM_SYSTEM_MANAGE_USER%3D%3DPERM_SYSTEM_MANAGE_SYSTEM%3D%3DPERM_SYSTEM_MANAGE_SHARED_WORKSPACE%3D%3DPERM_SYSTEM_VIEW_WORKSPACE%3D%3DPERM_SYSTEM_MANAGE_METADATA%3D%3DPERM_SYSTEM_MANAGE_WORKSPACESecure;SameSite=None;'
    // document.cookie = 'LOGIN_TOKEN_TYPE=bearerSecure;SameSite=None;'
    // document.cookie = 'REFRESH_LOGIN_TOKEN=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0X2xvZ2luX3RpbWUiOjE2MTIyNjc4ODEwMDAsInVzZXJfbmFtZSI6ImFkbWluIiwibGFzdF9sb2dpbl9pcCI6IjIxMi40Mi42Mi4yNDMiLCJzY29wZSI6WyJ3cml0ZSJdLCJleHAiOjE2MTIzMDc2NDgsIm9yZ19jb2RlcyI6W10sImF1dGhvcml0aWVzIjpbIlBFUk1fU1lTVEVNX01BTkFHRV9EQVRBU09VUkNFIiwiUEVSTV9TWVNURU1fTUFOQUdFX1BSSVZBVEVfV09SS1NQQUNFIiwiUEVSTV9TWVNURU1fTUFOQUdFX1VTRVIiLCJQRVJNX1NZU1RFTV9NQU5BR0VfU1lTVEVNIiwiX19QRVJNSVNTSU9OX01BTkFHRVIiLCJfX0FETUlOIiwiUEVSTV9TWVNURU1fTUFOQUdFX1NIQVJFRF9XT1JLU1BBQ0UiLCJfX1NIQVJFRF9VU0VSIiwiUEVSTV9TWVNURU1fVklFV19XT1JLU1BBQ0UiLCJfX0RBVEFfTUFOQUdFUiIsIlBFUk1fU1lTVEVNX01BTkFHRV9NRVRBREFUQSIsIlBFUk1fU1lTVEVNX01BTkFHRV9XT1JLU1BBQ0UiLCJfX1BSSVZBVEVfVVNFUiJdLCJqdGkiOiI5MmFhNTg2ZS01Y2MxLTQzNTYtYjQyZC05NWQ1MTUwMDJhZWUiLCJjbGllbnRfaWQiOiJwb2xhcmlzX2NsaWVudCJ9.p3jxdStRGLXpwS7iFY_Fft7k3YG-lsYBn_QpJAEiuOQSecure;SameSite=None;'
    // document.cookie = 'LOGIN_TOKEN=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0X2xvZ2luX3RpbWUiOjE2MTIyNjc4ODEwMDAsInVzZXJfbmFtZSI6ImFkbWluIiwibGFzdF9sb2dpbl9pcCI6IjIxMi40Mi42Mi4yNDMiLCJzY29wZSI6WyJ3cml0ZSJdLCJleHAiOjE2MTIzMDc2NDgsIm9yZ19jb2RlcyI6W10sImF1dGhvcml0aWVzIjpbIlBFUk1fU1lTVEVNX01BTkFHRV9EQVRBU09VUkNFIiwiUEVSTV9TWVNURU1fTUFOQUdFX1BSSVZBVEVfV09SS1NQQUNFIiwiUEVSTV9TWVNURU1fTUFOQUdFX1VTRVIiLCJQRVJNX1NZU1RFTV9NQU5BR0VfU1lTVEVNIiwiX19QRVJNSVNTSU9OX01BTkFHRVIiLCJfX0FETUlOIiwiUEVSTV9TWVNURU1fTUFOQUdFX1NIQVJFRF9XT1JLU1BBQ0UiLCJfX1NIQVJFRF9VU0VSIiwiUEVSTV9TWVNURU1fVklFV19XT1JLU1BBQ0UiLCJfX0RBVEFfTUFOQUdFUiIsIlBFUk1fU1lTVEVNX01BTkFHRV9NRVRBREFUQSIsIlBFUk1fU1lTVEVNX01BTkFHRV9XT1JLU1BBQ0UiLCJfX1BSSVZBVEVfVVNFUiJdLCJqdGkiOiI5MmFhNTg2ZS01Y2MxLTQzNTYtYjQyZC05NWQ1MTUwMDJhZWUiLCJjbGllbnRfaWQiOiJwb2xhcmlzX2NsaWVudCJ9.p3jxdStRGLXpwS7iFY_Fft7k3YG-lsYBn_QpJAEiuOQSecure;SameSite=None;'
    }

    // 로그인 사용자 판별 후 권한 체크
    return this.userService.isLoggedIn();
  }


}
