/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Created by Dolkkok on 2017. 7. 18..
 */
import {Component, ElementRef, Injector, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import '@ag-grid-enterprise/set-filter';
import 'ag-grid-enterprise';
import "@ag-grid-enterprise/column-tool-panel";
import "@ag-grid-enterprise/filter-tool-panel";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import data from "./input-grid-data";
import {AbstractComponent} from "../../abstract.component";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import {ModuleRegistry} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'input-grid-chart',
  template: `<ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%;"
    id="myGrid"
    class="ag-theme-balham"
    [modules]="modules"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [sideBar]="this.sideBar"
    [rowData]="rowData"
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular> `,
})
export class InputGridChartComponent extends AbstractComponent implements OnInit, OnDestroy {
  private gridApi;
  private gridColumnApi;

  public modules: Module[][] = [
    AllModules
  ];
  private columnDefs;
  private defaultColDef;
  private sideBar;
  private rowData: any;


  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    private http: HttpClient) {

    super(elementRef, injector);



    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      editable: true,
      sortable: true,
      filter: true,
      showToolPanel: true
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'columns',
    };
  }

  public ngOnInit() {
    super.ngOnInit();
    this.columnDefs = [];
    this.rowData = JSON.parse(localStorage.getItem('share'));

    let columnsKeys = Reflect.ownKeys(this.rowData[0]);
    //
    let modifyKeys = columnsKeys;
    //
    for (let item of modifyKeys) {
      this.columnDefs.push({field: item});
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.rowData = JSON.parse(localStorage.getItem('share'));
  }
}
